import BlockHeader from '@components/BlockHeader';
import { BlockContainer } from '@components/Blocks';
import CardGrid from '@components/CardGrid';
import CtaButton from '@components/CtaButton';
import EntryCard from '@components/EntryCard';
import { EntryCardWide } from '@components/EntryCard/EntryCard.Wide';
import { createBlock } from '@lib/features/blocks/blocks';
import { Entry, isEntry } from '@lib/features/entries';
import { useAppLink, useGlobalColorTheme, useView } from '@lib/store';
import { useTranslations } from 'use-intl';

const FeaturedEvents = createBlock<'blocks_featuredEvents_BlockType'>(
  ({ heading, content, eventMultiple, linkCta }) => {
    const indexLink = useAppLink('eventIndex');
    const t = useTranslations('event');
    const indexHref = indexLink?.uri || undefined;

    const [featuredEvent, ...events] = eventMultiple ?? [];
    const view = useView();
    const showIndexLink = !isEntry(view, 'eventIndex');

    const cta = !!linkCta?.href ? (
      <CtaButton {...linkCta} />
    ) : !!(showIndexLink && indexHref) ? (
      <CtaButton variant="text" href={indexHref}>
        {t('indexLink')}
      </CtaButton>
    ) : null;

    return (
      <BlockContainer
        colorTheme={useGlobalColorTheme()}
        colorSet="paper"
        paper
        disableMargin
        cx={{ paddingY: '5xl' }}>
        <BlockHeader
          {...{
            variant: 'split',
            heading,
            HeadingProps: { variant: 'h2' },
            content,
            cta,
          }}
        />

        {featuredEvent && (
          <EntryCardWide colorTheme="default" item={featuredEvent as Entry<'event'>} />
        )}

        {!!events.length && (
          <CardGrid
            colorTheme="default"
            Component={EntryCard}
            md={6}
            cx={{ marginTop: '2xl' }}
            items={events as Entry[]}
          />
        )}
      </BlockContainer>
    );
  }
);

export default FeaturedEvents;
